import React from "react"
import "./mystyles.scss"
import { navigate } from "gatsby"
import NameHeader from "../components/name-header";
import Helmet from "react-helmet";
import { graphql } from "gatsby"


const PositionHeader = (props) => {

  return (
    <div class="level">
      <div class="level-left">
        <div>
          <p class="title is-5">{props.company}</p>
          <p class="subtitle is-6">{props.title}</p>
        </div>
      </div>
      <div class="level-right">
        <div>
          <p class="subtitle is-7">{props.date}</p>
          <p></p>
        </div>
      </div>
    </div>
  );
}

const IndexPage = ({ data }) => {
  return (

    <div className="container">
      <Helmet>
        <title>Rusty Conover's Resume</title>
        <html lang="en" />
      </Helmet>

      <div class="columns">
        <div class="column is-2">
        </div>

        <div class="column is-8">

          <NameHeader image={data.file} />

          <section class="section is-small">
            <h2 class="title is-3">Description</h2>
            <div class="is-6">
              I am an experienced and innovative software executive with a track record of building businesses, platforms, and applications. I have expert-level knowledge of distributed systems, databases, networks, and operating systems. I enjoy solving problems creatively and growing high performing teams.
            </div>
          </section>

          <section class="section">
            <h2 class="title is-3">Experience</h2>

            <PositionHeader company="Schonfeld Strategic Advisors" title="Head of Cloud Data Platform" date="July 2021 - " />

            <div class="content">
              <ul>
                <li>
                  Leading a global team that is creating Schonfeld’s public cloud-based data platform for quantitative and systematic trading.
            </li>
                <li>
                  The platform performs ingestion, transformation, data quality assessments and secure authorization while offering durable availability, immutability, fully traceable data lineage, point-in-time querying and change data capture capabilities.
            </li>
                <li>
                  Created the platform utilizing open-source projects including Apache Arrow, Python, DuckDB, SQLite, and Apache Iceberg. Integrated those open source projects with services that AWS offers for data analytics (Athena, Lake Formation, S3, Lambda, Step Functions, S3, ECS, and Quicksight).
                </li>
                <li>The platform is agnostic to the choices of the user or portfolio manager, it integrates with everything from Jupyter Notebooks, DataBricks, KDB, and R.</li>
                <li>Built and recruited a diverse and well integrated team of developers.</li>
              </ul>
            </div>


            <PositionHeader company="J.P. Morgan Chase &amp; Company, New York" title="Executive Director / CTO" date="May 2018 - May 2019" />

            <div class="content">
              <ul>
                <li>
                  I was the CTO of ROAR, a distributed platform for real-time prediction and forecasting that compensated participants. I set the technical direction, including the architecture and implementation specifications.
          </li>
                <li>
                  I was responsible for project scoping and delivery milestones.
          </li>
                <li>Negotiated buy-in and approval from controls, cybersecurity, and compliance groups; managed vendor relationships.</li>
                <li>Recruited, mentored, and managed a team of developers, designers, and cryptographers, focusing on gender/cultural inclusion, to create a  team culture of equity and inclusion.</li>
                <li>I demonstrated technical innovation and direction-setting for cloud computing inside of the organization with a serverless architecture.</li>
                <li>I created detailed design documents, specifications, and reference implementations.</li>
                <li>I fostered collaboration with academic and industry researchers in machine learning, data science, and cryptography. I had particular success in explaining the benefit of secure multiparty computation to others.</li>
              </ul>
            </div>


            <PositionHeader company="Two Sigma Investments LLP, New York" title="Vice President" date="June 2015 - February 2018" />

            <div class="content">
              <ul>
                <li>Lead the technical efforts in a team that optimized, scaled, and delivered to production a platform that executed important and urgent quantitative simulation workloads for Two Sigma’s research on Amazon Web Services (AWS) and Google Cloud Platform (GCP).</li>
                <li>Designed and built a scalable, cloud-topology aware, cryptographically sound, and fault-tolerant caching framework to retain tens of petabytes of data.  This framework and provided hundreds of gigabits of bandwidth to applications with no additional dedicated caching resources.  This caching framework has scaled successfully to thousands of instances in production.</li>
                <li>I enhanced Docker by implementing a custom image layer driver. The driver stored the Docker image’s layers via Linux block devices rather than files. This enhancement allowed Docker images of any size without incurring the delay of sending the image’s contents to the executing instances.</li>
                <li>Extended and enhanced Mesos’ integration with Kerberos. Two Sigma operated exceedingly large Mesos clusters (thousands of instances).  I enhanced the integration between Apache Spark, Mesos, and the Cook scheduler.</li>
                <li>Worked to automate the builds of internal software releases for Docker images and full OS images for both AWS and GCP.</li>
                <li>I was a co-sponsor of the Javascript language group.  Performed security audits and change control of public cloud environments and deployments. I created an API proxy for AWS APIs that enforced enhanced security policies and an IAM policy linter.</li>
                <li>I utilized AWS and GCP to accelerate internal groups’ projects. I acted as a subject matter expert for all things involving cloud computing.  Mentored numerous new team members and demonstrated a talent for producing exceedingly capable colleagues from newly hired candidates.</li>
                <li>I was a vital member of an internal project that analyzed the output of trading simulations. These simulations produced billions of log messages. The errors we detected improved the robustness of simulations for quantitative researchers and portfolio managers.</li>
              </ul>
            </div>

            <PositionHeader company="Lucky Dinosaur LLC, New York" title="President and Founder" date="2014 - 2015" />


            <div class="content">
              <ul>
                <li>I contributed to creating a live video streaming, encoding, and distribution platform with B Productions used for New York Fashion Week, Lincoln Center, Metropolitan Museum of Art, and many other businesses and institutions.</li>
                <li>Planned and successfully delivered hundreds of terabytes of video content to millions of viewers around the world. Clients include top fashion brands such as Marc Jacobs, Calvin Klein, Michael Kors, and Tory Burch. Additionally, the platform provided a real-time video on the homepage of the New York Times. The architecture achieved a peak production request rate of over 850,000 requests per minute.</li>
                <li>I designed and built new encoding technology that achieved the ability to perform “live clipping,” it is a way to produce clips without requiring re-encoding the entire file. </li>
                <li>I built a cloud-based transcoder that would receive either HLS or RTMP input and stream to any designated set of formats and bitrates.</li>
                <li>I authored and tested high-quality adaptive video encoding strategies for iOS, Android, and Flash/RTMP clients. (H.264 with Adaptive Bitrates, HTTP Live Streaming, AAC audio encoding, RTMP, RTSP)</li>
                <li>I built scalable real-time analytics to provide visibility into viewership and distribution.</li>
              </ul>
            </div>

            <PositionHeader company="ParrotSnap LLC, New York" title="Co-founder / CTO" date="2014 - 2015" />


            <div class="content">
              <ul>
                <li>ParrotSnap is a cloud-based platform for financial modelers that helps them save, organize and annotate and search cross-referenced source information in Microsoft Excel.</li>
                <li>I developed and released various Perl open source modules.</li>
                <li>Failed to estimate the difficulty of selling to large financial institutions.</li>
              </ul>
            </div>

            <PositionHeader company="InfoGears Inc., Bozeman, Montana" title="Co-founder / CTO" date="2002 - 2014" />

            <div class="content">
              <ul>
                <li>Lead all aspects of technology development grew the business from a startup to a mature operating business. </li>
                <li>Supervised development and maintained a full software stack for e-commerce, CRM, and recurring billing with over $30 million in transactions annually.  Clients included AT&T, Outdoor Research, Icebreaker, and Simms Fishing.</li>
              </ul>
            </div>

            <PositionHeader company="GearBuyer, Bozeman, Montana" title="Co-founder / CTO" date="2007 - 2014" />

            <div class="content">
              <ul>
                <li>I conceptualized and developed GearBuyer.com - a price comparison website for outdoor gear and electronics.</li>
                <li>Sales grew from $0 to over $21 million. Partnered with over 100 merchants, including Amazon.com, Zappos, Backcountry.com, REI, Patagonia, Moosejaw, Backpacker Magazine, and InStyle/Time Inc.  Worked with online advertising platforms to broaden the reach of the website (Google Adwords, Twitter Advertising) </li>
                <li>Implemented web crawling infrastructure (HTTP/1.1 with sessions), duplicate detection, distributed redundant storage, an image similarity search engine (multidimensional color histograms, SIFT keypoints), XML parsing and data extraction, custom PostgreSQL database types, and integration with store data feeds and sales networks.</li>
                <li>I utilized dynamic programming techniques and simulated annealing to determine product uniqueness from multiple stores without using UPC or non-reliable product attributes.</li>
              </ul>
            </div>

            <PositionHeader company="Zoot Enterprises, Bozeman, Montana" title="Systems Programmer" date="1998 - 2002" />

            <div class="content">
              <ul>
                <li>Engineered and designed multiple aspects of the distributed consumer credit decision platform and web application stack. Specialized in distributed transaction processing, fraud prevention, systems redundancy.</li>
                <li>Evangelized the use of Perl, PHP, and various other scripting languages to speed up development time.</li>
                <li>I ported and optimized corporate applications to operating systems such as Linux, Solaris, and AIX.</li>
                <li>I created a database-agnostic connection layer to facilitate application portability.</li>
                <li>I shipped projects for top financial institutions in the USA, including Wells Fargo, LendingTree, and Bank of America.</li>
              </ul>
            </div>
          </section>

          <section class="section">
            <h2 class="title is-3">Education</h2>

            <PositionHeader company="Montana State University, Bozeman, Montana" title="B.S. Computer Science" date="1998 - 2002" />

          </section>


          <div class="has-text-centered">
            <button onClick={event => {
              event.preventDefault();
              navigate("/skills")
            }}
              class="button is-black is-rounded"> <span class="icon">
                <i class="fas fa-wrench"></i>
              </span>&nbsp;&nbsp; View Technical Experience</button>
          </div>


        </div >
      </div>
      <div class="column is-2">
      </div>


    </div >
  )
}

export const query = graphql`query HeaderImageQuery {
  file(name: {regex: "/rusty-river-full-round/"}) {
    childImageSharp {
      gatsbyImageData(width: 128, placeholder: NONE, layout: FIXED)
    }
  }
}
`;

export default IndexPage